import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { CoreModule } from '../core/core.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromStore from './store';
import { QuestionnairesOverviewComponent } from './container/questionnaires-overview/questionnaires-overview.component';
import { QuestionnairesRoutingModule } from './questionnaires-routing.module';
import { QuestionnaireDetailComponent } from './container/questionnaire-detail/questionnaire-detail.component';
import { ImageModule } from '@teamfoster/sdk/image';
import { IconModule } from '@teamfoster/sdk/icon';
import { FormModule } from '../form/form.module';
import { HeaderComponent } from '../standalone/header/header.component';
import { ButtonModule } from '@teamfoster/sdk/button';
import { QuestionnaireCardComponent } from './components/questionaire-card/questionnaire-card.component';
import { ProgressBarComponent } from '../core/components/progress-bar/progress-bar.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers),
    EffectsModule.forFeature(fromStore.effects),
    TextUtilityModule,
    DictionaryNgrxModule,
    CoreModule,
    QuestionnairesRoutingModule,
    ImageModule,
    IconModule,
    FormModule,
    ButtonModule,
    HeaderComponent,
    QuestionnaireCardComponent,
    ProgressBarComponent,
  ],
  declarations: [QuestionnairesOverviewComponent, QuestionnaireDetailComponent],
})
export class QuestionnairesModule {}
