import { isPlatformBrowser } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { DynamicFormConfig, DynamicFormConfigService, DynamicFormField, FormComponent } from '@teamfoster/dynamic-forms';
import { takeUntil } from 'rxjs';

interface FieldsetState {
  name: string;
  statusText: string | null;
  touched: boolean;
  active: boolean;
  valid: boolean;
}

interface FieldsetData {
  name: string;
  controls: {
    [k: string]: {
      control: AbstractControl<any, any>;
      config: DynamicFormField;
    };
  };
  [key: string]: any;
}
@Component({
  selector: 'app-form-stepper',
  templateUrl: './form-stepper.component.html',
  styleUrl: './form-stepper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormStepperComponent extends FormComponent implements OnInit, AfterContentInit, OnChanges {
  @Input() stepsTitle: string = 'Stappen'; // dictionary
  @Input() override fieldsets!: {
    name: string;
    title?: string | undefined;
    collapsed?: boolean | undefined;
    description?: string;
  }[];
  @Input() activeStep: number = 0;
  @Input() validateStepsOnInit: boolean = false;
  @Input() submitting: boolean = false;
  @Input() progress: number = 0;

  fieldsetStates!: {
    [key: string]: FieldsetState;
  };

  fieldsetData?: FieldsetData[];
  override triedSubmit: boolean = false;

  @ViewChild('formEl') formEl: ElementRef<any> | undefined;

  constructor(
    fb: UntypedFormBuilder,
    cd: ChangeDetectorRef,
    @Inject(DynamicFormConfigService)
    defaultFormConfig: DynamicFormConfig,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>
  ) {
    // {
    super(fb, cd, defaultFormConfig);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['submitting'] && changes['submitting'].currentValue) {
      this.triedSubmit = true;
    }
  }

  ngAfterContentInit(): void {
    // init fieldstates
    this.fieldsetStates = this.fieldsets.reduce(
      (ac, a, i) => ({
        ...ac,
        [a.name]: {
          name: a.name,
          statusText: null,
          touched: this.validateStepsOnInit,
          valid: false,
          active: i === this.activeStep,
        },
      }),
      {}
    );

    // Save which (field) controls and configs are in the fieldsets
    this.fieldsetData = this.fieldsets.map(fieldset => {
      const configs = this.config.filter(a => a.fieldset === fieldset.name);
      const controls = Object.fromEntries(
        configs
          .filter(config => config.name in this.form.controls) // line can be removed to make it inclusive
          //.map(b => ({control: b, label: ''}))
          .map(config => [config.name, { control: this.form.controls[config.name], config }])
      );

      return {
        controls: controls,
        ...fieldset,
      };
    });

    if (this.validateStepsOnInit) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      this.validateStep(true);
    }

    //this.form.valueChanges.pipe(takeUntil(this._unsubscribe)).subscribe(() => this.validateStep(true));

    this.cd.detectChanges();
  }

  get currentStepIsValid() {
    return this.fieldsetStates[this.fieldsets[this.activeStep].name].valid;
  }

  override onSubmit() {
    this.form.markAllAsTouched();
    this.validateStep(true);

    if (this.firstInvalidFieldName) {
      this.scrollToFirstInvalidField();
      return;
    }

    this.triedSubmit = true;

    if (this.form.valid) {
      this.formSubmit.emit(this.form.value);
    }
  }

  validateStep(validateAllSteps: boolean = false) {
    if (validateAllSteps) {
      this.fieldsetData?.forEach(f => {
        this.validate(f);
        this.fieldsetStates[f.name].touched = true;
      });
    } else {
      this.validate(this.fieldsetData![this.activeStep]);
    }

    this.cd.detectChanges();
  }

  get firstInvalidFieldName() {
    return Object.keys(this.fieldsetData![this.activeStep].controls).find(
      key => this.fieldsetData![this.activeStep].controls[key].control.invalid
    );
  }
  scrollToFirstInvalidField() {
    if (!this.currentStepIsValid) {
      if (isPlatformBrowser(this.platformId) && this.firstInvalidFieldName) {
        const el = document.getElementById(this.firstInvalidFieldName);
        el?.scrollIntoView({ behavior: 'smooth' });
      }

      return;
    }
  }

  validate(f: FieldsetData) {
    const invalidFields = Object.keys(f.controls).filter(key => {
      f.controls[key].control.markAsTouched();
      f.controls[key].control.updateValueAndValidity();
      return f.controls[key].control.invalid;
    });

    this.cd.detectChanges();
    this.cd.markForCheck();

    this.fieldsetStates[f.name].valid = !invalidFields.length;
    this.setFieldsetErrortext(this.fieldsetStates[f.name], invalidFields, f);
  }

  goToSection(fieldsetName: string) {
    // scroll to top position
    if (this.formEl && this.formEl.nativeElement) {
      const scrollTop = this.formEl.nativeElement.offsetTop - 100;

      window.scroll({
        top: scrollTop,
        behavior: 'smooth',
      });
    }

    const currentActiveName = this.fieldsets[this.activeStep].name;
    this.fieldsetStates[currentActiveName].touched = true;

    Object.keys(this.fieldsetStates).forEach((a, i) => {
      if (a === fieldsetName) {
        this.activeStep = i;
        this.fieldsetStates[a].active = true;
      } else {
        this.fieldsetStates[a].active = false;
      }
    });

    this.cd.detectChanges();
  }

  nextStep() {
    this.validateStep();

    if (this.firstInvalidFieldName) {
      this.scrollToFirstInvalidField();
      return;
    }

    if (this.activeStep < this.fieldsets.length) {
      this.activeStep += 1;
      this.goToSection(this.fieldsets[this.activeStep].name);
    }
  }

  prevStep() {
    this.validateStep();

    if (this.activeStep > 0) {
      this.activeStep -= 1;
      this.goToSection(this.fieldsets[this.activeStep].name);
    }
  }

  setFieldsetErrortext(activeFieldsetState: FieldsetState, invalidFields: string[], fieldset: FieldsetData) {
    if (invalidFields.length) {
      if (invalidFields.length < 3) {
        activeFieldsetState.statusText = `Velden niet correct gevuld: ${invalidFields
          .map(a => fieldset.controls[a].config.label)
          .join(', ')}`;
      } else {
        activeFieldsetState.statusText = `${invalidFields.length} velden niet correct gevuld`;
      }
    } else {
      activeFieldsetState.statusText = '';
    }
  }

  // get progress() {
  //   if (this.triedSubmit) {
  //     return 100;
  //   }
  //   return (this.activeStep / this.fieldsets.length) * 100;
  // }
}
