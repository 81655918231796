import { Injectable, inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';
import { AnswerService } from '../../services/answer.service';
import * as actions from '../actions/answer.action';
import * as questionnaireActions from '../actions/questionnaires.action';
import { Go } from 'src/app/store';

@Injectable()
export class AnswersEffects {
  constructor(
    private actions$: Actions,
    private services: AnswerService
  ) {}

  LoadAnswersById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadAnswersById),
      switchMap(({ id }) =>
        this.services.getAnswersByUserListId(id).pipe(
          map(answers => actions.LoadAnswersByIdSuccess({ answers })),
          catchError(error => of(actions.LoadAnswersByIdFail({ error, id })))
        )
      )
    );
  });

  LoadAnswersByIdFail = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadAnswersByIdFail),
      map(({ error }) => {
        return Go({ path: ['/' + error?.status ? error.status : '404'], extras: { skipLocationChange: true } });
      })
    );
  });

  CreateAnswer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.CreateAnswer),
      switchMap(({ answerData }) =>
        this.services.createAnswer(answerData).pipe(
          map(answer => actions.CreateAnswerSuccess({ answer })),
          catchError(error => of(actions.CreateAnswerFail({ error })))
        )
      )
    );
  });

  CreateAnswerSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.CreateAnswerSuccess),
        tap(() => {})
      );
    },
    { dispatch: false }
  );

  UpdateAnswer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.UpdateAnswer),
      switchMap(({ answerData }) =>
        this.services.updateAnswer(answerData).pipe(
          map(answer => actions.UpdateAnswerSuccess({ answer, answerData })),
          catchError(error => of(actions.UpdateAnswerFail({ error })))
        )
      )
    );
  });

  UpdateAnswerSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.UpdateAnswerSuccess),
        tap(() => {})
      );
    },
    { dispatch: false }
  );
}
