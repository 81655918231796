import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromQuestionsLists from '../reducers/questionnaires.reducer';
import * as fromRoot from '../../../store';
import { Questionnaire } from '../../models';
import { UserQuestionnaire } from '../../models/user-questionnaire.model';
import { getSelectedQuestionsListAnswers as getSelectedQuestionnaireAnswers } from './answer.selector';

export const getQuestionnairesState = createSelector(
  fromFeature.getQuestionnaireState,
  (state: fromFeature.QuestionnaireState) => state.questionnaires
);

export const getQuestionnairesEntities = createSelector(getQuestionnairesState, fromQuestionsLists.getQuestionnairesEntities);
export const getQuestionnairesLoaded = createSelector(getQuestionnairesState, fromQuestionsLists.getQuestionnairesLoaded);
export const getQuestionnairesLoading = createSelector(getQuestionnairesState, fromQuestionsLists.getQuestionnairesLoading);

export const getAllQuestionnaires = createSelector(getQuestionnairesEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]);
});

export const getOrderedQuestionnaires = createSelector(getAllQuestionnaires, questionsList => {
  return questionsList.sort((a, b) => (b.questionnaire.order >= a.questionnaire.order ? 1 : -1));
});

export const getQuestionnairesOverviewLoaded = createSelector(getQuestionnairesState, fromQuestionsLists.getQuestionnairesOverviewLoaded);
export const getQuestionnairesOverviewLoading = createSelector(getQuestionnairesState, fromQuestionsLists.getQuestionnairesOverviewLoading);

export const getSelectedQuestionnaire = createSelector(
  getQuestionnairesEntities,
  fromRoot.getRouterState,
  (entities, router): UserQuestionnaire => {
    return router.state && entities[+router.state.params?.['questionsListId']];
  }
);

export const getSelectedQuestionnaireLoading = createSelector(
  getQuestionnairesLoading,
  fromRoot.getRouterState,
  (loading, router): boolean => {
    return router.state && loading.includes(+router.state.params?.['questionsListId']);
  }
);

export const getSelectedQuestionnaireLoaded = createSelector(
  getQuestionnairesLoaded,
  fromRoot.getRouterState,
  (loaded, router): boolean => {
    return router.state && loaded.includes(+router.state.params?.['questionsListId']);
  }
);

export const getSelectedQuestionnaireWithAnswers = createSelector(
  getSelectedQuestionnaire,
  getSelectedQuestionnaireAnswers,
  (list, answers): Questionnaire => {
    let combinedList: Questionnaire = {
      ...list.questionnaire,
      questionsGroups: list.questionnaire.questionsGroups.map(group => {
        return {
          ...group,
          questions: group.questions.map(question => {
            return {
              ...question,
              answer: answers.find(a => a.questionId === question.id),
            };
          }),
        };
      }),
    };
    return combinedList;
  }
);

export const getSelectedQuestionnaireProgressGroup = createSelector(getSelectedQuestionnaireWithAnswers, list => {
  let progress = 0;
  list.questionsGroups.forEach(group => {
    let questions = group.questions.filter(q => q.required).length;
    let answered = group.questions.filter(q => (q.answer?.answerText || q.answer?.multipleChoiceAnswer) && q.required).length;
    if (answered === questions && questions != 0) {
      progress += 1;
    }
  });

  return progress;
});

export const getSelectedQuestionnaireProgress = createSelector(getSelectedQuestionnaireWithAnswers, list => {
  let answerstotal = 0;
  let questionsTotal = 0;

  list.questionsGroups.forEach(group => {
    let questions = group.questions.filter(q => q.required);
    answerstotal += group.questions.filter(q => (q.answer?.answerText || q.answer?.multipleChoiceAnswer) && q.required).length;
    questionsTotal += questions.length;
  });

  return (answerstotal / questionsTotal) * 100;
});
