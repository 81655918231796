import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { getOrderedQuestionnaires } from '../../store';

@Component({
  selector: 'app-questionnaires-overview',
  templateUrl: './questionnaires-overview.component.html',
  styleUrl: './questionnaires-overview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnairesOverviewComponent {
  private store = inject(Store);

  userQuestionnaires$ = this.store.selectSignal(getOrderedQuestionnaires);

  questionnairesInProgress$ = computed(() => {
    const questionnaires = this.userQuestionnaires$();
    return questionnaires.filter(q => q.progress > 0 && q.progress < 100);
  });

  questionnairesTodo$ = computed(() => {
    const questionnaires = this.userQuestionnaires$();
    return questionnaires.filter(q => q.progress === 0 || !q.progress);
  });

  questionnairesDone$ = computed(() => {
    const questionnaires = this.userQuestionnaires$();
    return questionnaires.filter(q => q.progress === 100);
  });
}
