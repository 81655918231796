import { createAction, props } from '@ngrx/store';
import { AnswerData, UserQuestionnaire, UserQuestionnaireData } from '../../models';

export const LoadQuestionnaires = createAction('[Questionnaires] Load Questionnaires');
export const LoadQuestionnairesSuccess = createAction(
  '[Questionnaires] Load Questionnaires Success',
  props<{ questionnaires: UserQuestionnaire[] }>()
);
export const LoadQuestionnairesFail = createAction('[Questionnaires] Load Questionnaires Fail', props<{ error: any }>());

export const LoadQuestionnaireById = createAction('[Questionnaires] Load Questionnaire by Id', props<{ id: number }>());
export const LoadQuestionnaireByIdSuccess = createAction(
  '[Questionnaires] Load Questionnaire by Id Success',
  props<{ questionnaire: UserQuestionnaire }>()
);
export const LoadQuestionnaireByIdFail = createAction(
  '[Questionnaires] Load Questionnaire by Id Fail',
  props<{ error: any; id: number }>()
);

export const UpdateQuestionnaireProgress = createAction(
  '[Questionnaires] Update Questionnaire Progress',
  props<{ questionnaireData: UserQuestionnaireData }>()
);
export const UpdateQuestionnaireProgressSuccess = createAction(
  '[Questionnaires] Update Questionnaire Progress Success',
  props<{ questionnaire: UserQuestionnaire }>()
);
export const UpdateQuestionnaireProgressFail = createAction('[Questionnaires] Update Questionnaire Progress Fail', props<{ error: any }>());

export const ClearQuestionnaires = createAction('[Questionnaires] Clear Questionnaires');
