import { Injectable, inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as questionsListActions from '../actions/questionnaires.action';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { QuestionnairesService } from '../../services';
import { Go } from 'src/app/store';

@Injectable()
export class QuestionnairesEffects {
  constructor(
    private actions$: Actions,
    private services: QuestionnairesService
  ) {}

  LoadQuestionsList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(questionsListActions.LoadQuestionnaires),
      switchMap(() =>
        this.services.getQuestionnaires().pipe(
          map(questionnaires => questionsListActions.LoadQuestionnairesSuccess({ questionnaires })),
          catchError(error => of(questionsListActions.LoadQuestionnairesFail({ error })))
        )
      )
    );
  });

  LoadQuestionsListById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(questionsListActions.LoadQuestionnaireById),
      switchMap(({ id }) =>
        this.services.getQuestionnaireById(id).pipe(
          map(questionnaire => questionsListActions.LoadQuestionnaireByIdSuccess({ questionnaire })),
          catchError(error => of(questionsListActions.LoadQuestionnaireByIdFail({ error, id })))
        )
      )
    );
  });

  LoadQuestionsListByIdFail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(questionsListActions.LoadQuestionnaireByIdFail),
      map(({ error }) => {
        return Go({ path: ['/' + error?.status ? error.status : '404'], extras: { skipLocationChange: true } });
      })
    );
  });

  UpdateQuestionnaireProgress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(questionsListActions.UpdateQuestionnaireProgress),
      switchMap(({ questionnaireData }) =>
        this.services.updateQuestionnaireProgress(questionnaireData).pipe(
          map(questionnaire => questionsListActions.UpdateQuestionnaireProgressSuccess({ questionnaire })),
          catchError(error => of(questionsListActions.UpdateQuestionnaireProgressFail({ error })))
        )
      )
    );
  });

  UpdateQuestionnaireProgressSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(questionsListActions.UpdateQuestionnaireProgressSuccess),
        tap(() => {})
      );
    },
    { dispatch: false }
  );
}
