import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { getSelectedQuestionnaire } from '../../store';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { HeaderComponent } from 'src/app/standalone/header/header.component';
import { LinkComponent } from '@teamfoster/sdk/button';
import { ImageComponent, ImageModule } from '@teamfoster/sdk/image';
import { IconModule } from '@teamfoster/sdk/icon';
import { RouterLink } from '@angular/router';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';

@Component({
  selector: 'app-questionnaire-intro',
  templateUrl: './questionnaire-intro.component.html',
  standalone: true,
  imports: [DictionaryNgrxModule, HeaderComponent, ImageModule, IconModule, RouterLink, TextUtilityModule],
  styleUrl: './questionnaire-intro.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnaireIntroComponent {
  private store = inject(Store);

  selectedQuestionnaire$ = this.store.selectSignal(getSelectedQuestionnaire);
}
