import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromRoot from '../../../store';
import * as fromAnswers from '../reducers/answer.reducer';
import { Answer } from '../../models/answer.model';

export const getAnswersState = createSelector(fromFeature.getQuestionnaireState, (state: fromFeature.QuestionnaireState) => state.answers);

export const getAnswersEntities = createSelector(getAnswersState, fromAnswers.getAnswersEntities);

export const getAllAnswers = createSelector(getAnswersEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]);
});

export const getOrderedAnswers = createSelector(getAllAnswers, answers => {
  return answers.sort((a, b) => (a.questionId >= b.questionId ? 1 : -1));
});

export const getAnswersLoaded = createSelector(getAnswersState, fromAnswers.getAnswersLoaded);
export const getAnswersLoading = createSelector(getAnswersState, fromAnswers.getAnswersLoading);

export const getAnswersSaved = createSelector(getAnswersState, fromAnswers.getAnswersSaved);
export const getAnswersSaving = createSelector(getAnswersState, fromAnswers.getAnswersSaving);

export const getAnswersSavingFailed = createSelector(getAnswersState, fromAnswers.getAnswersSavingFailed);

export const getSelectedQuestionsListAnswers = createSelector(getOrderedAnswers, fromRoot.getRouterState, (answers, router): Answer[] => {
  return router.state && answers.filter(a => a.questionsListUserId === +router.state.params?.['questionsListId']);
});

export const getSelectedQuestionsListProgress = createSelector(getOrderedAnswers, fromRoot.getRouterState, (answers, router): Answer[] => {
  return router.state && answers.filter(a => a.questionsListUserId === +router.state.params?.['questionsListId']);
});
