<!-- @if (questionFormFields$(); as formFields) { -->
<!-- <pre>Quiz {{ selectedQuestionnaire$() | json }}</pre> -->
<!--<pre>Aantal mapped vragen: {{ formFields.length }}</pre>-->

<app-form-stepper
  #form
  [config]="questionFormFields$() || []"
  [fieldsets]="fieldsets$() || []"
  [progress]="userQuestionnaireProgress$()"
  (formSubmit)="handleSubmit($event)"
  [submitting]="submitting"
  [activeStep]="latestAnsweredGroupNumber$() || 0"
>
  <ng-container slot="container-top">
    <div class="container">
      <button
        class="button button-link button--primary mt-4"
        [routerLink]="['/vragenlijsten', userQuestionnaire$().id, selectedQuestionnaire$()?.title || '' | urlRewrite, 'introductie']"
      >
        <fstr-icon anchor="icon-arrow-left"></fstr-icon>
        <span>Terug naar introductie</span>
      </button>
    </div>
  </ng-container>

  <!-- @if (!selectedQuestionnaire$()?.questions?.length) {
    <app-alert slot="form-content" [status]="'info'" [message]="'vragenlijst-zonder-vragen-melding' | fromDictionary"></app-alert>
  } -->

  @if (anwserSaveError$(); as error) {
    <app-alert slot="form-alert" [status]="'danger'" [message]="'opslaan-mislukt' | fromDictionary" class="d-block mb-3"></app-alert>
  }
</app-form-stepper>
