import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { DynamicFormField } from '@teamfoster/dynamic-forms';
import { DictionaryNgrxModule, FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { FormModule } from 'src/app/form/form.module';

@Component({
  selector: 'app-temporary',
  standalone: true,
  imports: [CommonModule, FormModule, DictionaryNgrxModule],
  templateUrl: './temporary.component.html',
  styleUrl: './temporary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemporaryComponent {
  private lang = inject(LanguageService);
  private dict = new FromDictionaryPipe(this.lang);

  prefix = 'voorbeeld';

  fieldsets = [
    {
      name: 'group1',
      title: 'Groep 1',
      description: this.dict.transform(`test`, false),
    },
    {
      name: 'group2',
      title: 'Groep 2',
      description: this.dict.transform(`test`, false),
    },
    {
      name: 'group3',
      title: 'Groep 3',
      description: this.dict.transform(`test`, false),
    },
  ];

  exampleFormFields = Array.from({ length: 20 }, (_, i) => ({ id: i, question: `Voorbeeldvraag ${i}`, description: '', answer: '' }));

  formFields: DynamicFormField[] = this.exampleFormFields.map(question => {
    // devide the questions into 3 groups
    let fieldset = '';
    if (question.id < 7) {
      fieldset = 'group1';
    } else if (question.id < 14) {
      fieldset = 'group2';
    } else {
      fieldset = 'group3';
    }

    return {
      inputType: 'text',
      //label: this.dict.transform(`${this.prefix}-veld-label-titel`),
      label: question.question,
      subLabel: question.description,
      name: 'vraag' + question.id.toString(),
      validators: [Validators.required],
      value: question.answer,
      placeholder: this.dict.transform(`standaard-veld-placeholder-titel`),
      fieldType: 'input',
      order: 0,
      fieldset: fieldset,
    };
  });

  handleSubmit(data: any) {
    console.log(data.vraag1);
  }
}
