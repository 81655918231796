import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserQuestionnaire } from '../models/user-questionnaire.model';
import { UserQuestionnaireData } from '../models';

@Injectable({
  providedIn: 'root',
})
export class QuestionnairesService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getQuestionnaires() {
    return this.http.get<UserQuestionnaire[]>(`${this.BASE_URL}api/questionnaires`);
  }

  getQuestionnaireById(id: number) {
    return this.http.get<UserQuestionnaire>(`${this.BASE_URL}api/questionnaires/${id}`);
  }

  updateQuestionnaireProgress(questionnaireData: UserQuestionnaireData) {
    const formData: FormData = new FormData();
    formData.append('id', questionnaireData.id.toString());
    formData.append('progress', questionnaireData.progress.toString());

    return this.http.put<UserQuestionnaire>(`${this.BASE_URL}api/questionnaires/${questionnaireData.id}`, formData);
  }
}
