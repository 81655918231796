import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@teamfoster/sdk/icon';
import { RouterModule } from '@angular/router';
import { ImageModule } from '@teamfoster/sdk/image';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { Questions } from '../../models/questions.model';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { Questionnaire, UserQuestionnaire } from '../../models';

@Component({
  selector: 'app-questionnaire-card',
  standalone: true,
  imports: [CommonModule, IconModule, RouterModule, ImageModule, TextUtilityModule, DictionaryNgrxModule],
  templateUrl: './questionnaire-card.component.html',
  styleUrls: ['./questionnaire-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnaireCardComponent {
  @Input({ required: true }) userQuestionnaire!: UserQuestionnaire;
  @Input() cssClasses: string = '';

  constructor() {}
}
