<div class="form-stepper bg-blue-100">
  <ng-content select="[slot=container-top]"></ng-content>

  <div class="container container-size-xs">
    <form
      [formGroup]="form"
      (ngSubmit)="onSubmit(); validateStep()"
      class="form row section-inset-y"
      [class.hide--validation]="!showValidation"
      [ngClass]="'form--' + appearance"
      #formEl
    >
      <fieldset class="fieldset col-12" *ngFor="let f of fieldsets; let fieldIndex = index">
        <div *ngIf="fieldsetStates?.[f.name] as state" [class.d-none]="!state.active">
          <!-- If we want to show the group title and tekst -->
          <!--<div class="fieldset__header">
            <legend class="h5 fw-bold text-primary font-secondary mb-3">{{ f.title || f.name }}</legend>
            <div class="mb-4" *ngIf="f.description" [innerHTML]="f.description"></div>
          </div>-->
          <div class="row fieldset__row" *ngIf="(config | forFieldset: f.name).length">
            <div
              [ngClass]="field.cssClass ?? 'col-12'"
              *ngFor="let field of config | forFieldset: f.name"
              [class.mb-2]="field.fieldType !== 'hidden'"
              [id]="field.name"
            >
              <div fstrDynamicField [config]="field" [group]="form" *ngIf="field.control !== 'none' && shouldBeDisplayed(field)"></div>
            </div>
          </div>
        </div>
      </fieldset>

      <ng-content select="[slot=form-content]"></ng-content>

      <div class="col-12 mt-3">
        @if (activeStep + 1 === fieldsets.length && triedSubmit) {
          <ng-container *ngIf="showAlerts">
            <div *ngIf="!form.valid" class="alert alert--danger">
              <span>{{ inputErrorMessage || defaultFormConfig.genericInputErrorMessage }}</span>
            </div>

            <div *ngIf="serverError" class="alert alert--danger">
              <span>{{ serverErrorMessage || defaultFormConfig.genericServerErrorMessage }}</span>
            </div>
          </ng-container>
        }
        <ng-content select="[slot=form-alert]"></ng-content>
      </div>
    </form>
  </div>
</div>

<nav class="form-stepper-nav">
  <div class="form-stepper-nav__inner container">
    <button class="button button--muted form-stepper-nav__button" (click)="prevStep()" [disabled]="activeStep === 0">
      <fstr-icon anchor="icon-arrow-left"></fstr-icon>
      <span>Vorige</span>
    </button>

    <app-progress-bar class="form-stepper-nav__progress d-flex align-items-center" [progress]="progress"></app-progress-bar>

    @if (activeStep + 1 !== fieldsets.length) {
      <button class="button button--primary form-stepper-nav__button" (click)="nextStep()">
        <span>Volgende</span>
        <fstr-icon anchor="icon-arrow-right"></fstr-icon>
      </button>
    }
    @if (activeStep + 1 === fieldsets.length) {
      <button class="button button--primary ms-auto me-0" (click)="onSubmit()">
        <span>Antwoorden versturen</span>
        @if (!submitting) {
          <fstr-icon anchor="icon-arrow-right"></fstr-icon>
        }
        @if (submitting) {
          <fstr-icon anchor="icon-loading" class="anim--spinning"></fstr-icon>
        }
      </button>
    }
  </div>

  <!--<h2 class="h5 text-primary font-secondary fw-bold mb-4" *ngIf="stepsTitle">{{ activeStep + 1 }}/{{ fieldsets.length }}</h2>-->
  <!--<ul class="stepper-list">
    <li class="stepper-list__item" *ngFor="let f of fieldsets; let i = index">
      <button
        class="stepper-button"
        [class.is--untouched]="!state.touched && !state.active"
        [class.is--active]="state.active"
        [class.is--valid]="state.valid && !state.active"
        [class.is--invalid]="!state.valid && state.touched && !state.active"
        (click)="validateStep(); goToSection(f.name)"
        *ngIf="fieldsetStates?.[f.name] as state"
      >
        <div class="stepper-button__figure me-2">
          <span class="stepper-button__chip" [ngClass]="'is--' + (state.valid ? 'valid' : 'invalid')">
            <fstr-icon anchor="icon-warning" *ngIf="!state.valid && state.touched && !state.active"></fstr-icon>
            <fstr-icon anchor="icon-check" *ngIf="state.valid && !state.active"></fstr-icon>
            <fstr-icon anchor="icon-edit" *ngIf="state.active"></fstr-icon>
            <span *ngIf="!state.touched && !state.active && !state.valid">{{ i + 1 }}</span>
          </span>
        </div>
        <div class="stepper-button__content">
          <strong class="d-inline-block w-100">{{ f.title }}</strong>
          <ng-container *ngIf="!state.active">
            <span class="text-danger type-body-xs" *ngIf="state?.statusText as text">{{ text }}</span>
          </ng-container>
        </div>
      </button>
    </li>
  </ul>-->
</nav>

<pre *ngIf="debug" class="section-inset-t">
  {{ form.getRawValue() | json }}
</pre>
