import { createReducer, on } from '@ngrx/store';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { Answer } from '../../models/answer.model';
import * as answerActions from '../actions/answer.action';

export interface AnswersState {
  entities: { [id: number]: Answer };
  loaded: boolean;
  loading: boolean;
  error?: any;
  saving: boolean;
  saved: boolean;
}

export const initialState: AnswersState = {
  entities: {},
  loaded: false,
  loading: false,
  saved: false,
  saving: false,
};

export const AnswersReducer = createReducer(
  initialState,
  on(answerActions.LoadAnswersById, (state: AnswersState, { id }) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(answerActions.LoadAnswersByIdSuccess, (state: AnswersState, { answers }) => {
    const entities = ToEntities(answers, 'id', state.entities);
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(answerActions.LoadAnswersByIdFail, (state: AnswersState, { error, id }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  }),
  on(answerActions.DeleteAnswerSuccess, (state: AnswersState, { answer }) => {
    const { [answer.id]: removed, ...entities } = state.entities;
    return {
      ...state,
      entities,
    };
  }),
  on(answerActions.CreateAnswer, (state: AnswersState) => {
    return {
      ...state,
      saved: false,
      saving: true,
    };
  }),
  on(answerActions.CreateAnswerSuccess, (state: AnswersState, { answer }) => {
    const entities = {
      ...state.entities,
      [answer.id]: answer,
    };
    return {
      ...state,
      entities,
      saved: true,
      saving: false,
    };
  }),
  on(answerActions.UpdateAnswer, (state: AnswersState) => {
    return {
      ...state,
      saved: false,
      saving: true,
    };
  }),
  on(answerActions.UpdateAnswerSuccess, (state: AnswersState, { answer }) => {
    const entities = {
      ...state.entities,
      [answer.id]: answer,
    };
    return {
      ...state,
      entities,
      saved: true,
      saving: false,
    };
  }),
  on(answerActions.ClearAnswers, (state: AnswersState) => {
    return {
      ...initialState,
    };
  })
);

export const getAnswersEntities = (state: AnswersState) => state.entities;
export const getAnswersLoading = (state: AnswersState) => state.loading;
export const getAnswersLoaded = (state: AnswersState) => state.loaded;
export const getAnswersSaved = (state: AnswersState) => state.saved;
export const getAnswersSaving = (state: AnswersState) => state.saving;
export const getAnswersSavingFailed = (state: AnswersState) => state.error;
