import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuestionnairesOverviewGuard, QuestionnaireDetailGuard } from './guards';
import { QuestionnairesOverviewComponent } from './container/questionnaires-overview/questionnaires-overview.component';
import { QuestionnaireIntroComponent } from './container/questionnaire-intro/questionnaire-intro.component';
import { QuestionnaireDetailComponent } from './container/questionnaire-detail/questionnaire-detail.component';
import { TemporaryComponent } from './container/temporary/temporary.component';
import { QuestionnaireCompletedComponent } from './container/questionnaire-completed/questionnaire-completed.component';
import { AnswerGuard } from './guards/answer.guard';

const routes: Routes = [
  {
    path: ':questionsListId/vragen',
    pathMatch: 'full',
    component: QuestionnaireDetailComponent,
    canActivate: [QuestionnaireDetailGuard, AnswerGuard],
  },
  {
    path: ':questionsListId/:target/introductie',
    component: QuestionnaireIntroComponent,
    canActivate: [QuestionnaireDetailGuard],
  },
  {
    path: ':questionsListId/:target/einde',
    component: QuestionnaireCompletedComponent,
    canActivate: [QuestionnaireDetailGuard],
  },
  {
    path: '',
    component: QuestionnairesOverviewComponent,
    canActivate: [QuestionnairesOverviewGuard],
  },
  {
    path: 'temp',
    component: TemporaryComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuestionnairesRoutingModule {}
