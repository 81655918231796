import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Answer, AnswerData } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AnswerService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getAnswersByUserListId(id: number) {
    return this.http.get<Answer[]>(`${this.BASE_URL}api/questions-lists/${id}/answers`);
  }

  createAnswer(answer: AnswerData) {
    const formData: FormData = new FormData();
    formData.append('questionsListUserId', answer.UserQuestionnaireId.toString());
    formData.append('questionId', answer.questionId.toString());
    formData.append('answerText', answer.answerText);
    formData.append('multipleChoiceAnswer', answer.multipleChoiceAnswer);

    return this.http.post<Answer>(
      `${this.BASE_URL}api/questions-lists/${answer.UserQuestionnaireId}/answers/${answer.questionId}`,
      formData
    );
  }

  updateAnswer(answer: AnswerData) {
    const formData: FormData = new FormData();
    formData.append('questionsListUserId', answer.UserQuestionnaireId.toString());
    formData.append('questionId', answer.questionId.toString());
    formData.append('answerText', answer.answerText);
    formData.append('multipleChoiceAnswer', answer.multipleChoiceAnswer);

    return this.http.put<Answer>(
      `${this.BASE_URL}api/questions-lists/${answer.UserQuestionnaireId}/answers/${answer.questionId}`,
      formData
    );
  }
}
