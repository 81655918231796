<article class="card shadow-lg" [ngClass]="cssClasses">
  <div class="card-body d-flex flex-column flex-md-row align-items-end align-items-md-center">
    <header class="w-100">
      <h2 class="h4 text-primary card-title">{{ userQuestionnaire.questionnaire.title }}</h2>
      <p class="text-max-width-sm mb-0">{{ userQuestionnaire.questionnaire.introText | stripHtml }}</p>
    </header>
    <button
      class="button button--primary"
      [routerLink]="['/vragenlijsten', userQuestionnaire.id, userQuestionnaire.questionnaire.title | urlRewrite, 'introductie']"
    >
      @if (userQuestionnaire.progress === 100) {
        <span>{{ 'vragenlijst-overzicht-card-afgerond-button-label' | fromDictionary }}</span>
      } @else {
        <span>{{ 'vragenlijst-overzicht-card-button-label' | fromDictionary }}</span>
      }
    </button>
  </div>
  <ng-content select="[footer]"></ng-content>
</article>
