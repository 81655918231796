import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/router';
import { RouterModule } from '@angular/router';
function LinkComponent_a_0_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r5.button.text);
  }
}
function LinkComponent_a_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function LinkComponent_a_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 4);
    i0.ɵɵtemplate(1, LinkComponent_a_0_span_1_Template, 2, 1, "span", 2)(2, LinkComponent_a_0_ng_container_2_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    const _r4 = i0.ɵɵreference(4);
    i0.ɵɵproperty("href", ctx_r0.button.url, i0.ɵɵsanitizeUrl)("target", ctx_r0.target)("ngClass", ctx_r0.cssClasses);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.showText);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", _r4);
  }
}
function LinkComponent_a_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r7.button.text);
  }
}
function LinkComponent_a_1_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function LinkComponent_a_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 6);
    i0.ɵɵtemplate(1, LinkComponent_a_1_span_1_Template, 2, 1, "span", 2)(2, LinkComponent_a_1_ng_container_2_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    const _r4 = i0.ɵɵreference(4);
    i0.ɵɵproperty("ngClass", ctx_r1.cssClasses)("routerLink", ctx_r1.button.routerLink)("routerLinkActive", ctx_r1.routerLinkActive)("queryParams", ctx_r1.queryParams)("fragment", ctx_r1.fragment)("routerLinkActiveOptions", ctx_r1.routerLinkActiveOptions);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.showText);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", _r4);
  }
}
function LinkComponent_span_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function LinkComponent_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtemplate(1, LinkComponent_span_2_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const _r4 = i0.ɵɵreference(4);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", _r4);
  }
}
function LinkComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
const _c0 = ["*"];
class LinkComponent {
  constructor() {
    this.showText = true;
    this.noLink = false; // Use this to conditionally wrap in a <a> or not
    this.cssClasses = '';
    this.target = '_blank';
    this.routerLinkActive = 'is--active';
    this.routerLinkActiveOptions = {
      exact: false
    };
  }
  static {
    this.ɵfac = function LinkComponent_Factory(t) {
      return new (t || LinkComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LinkComponent,
      selectors: [["fstr-link"]],
      inputs: {
        button: "button",
        showText: "showText",
        noLink: "noLink",
        cssClasses: "cssClasses",
        target: "target",
        routerLinkActive: "routerLinkActive",
        queryParams: "queryParams",
        fragment: "fragment",
        routerLinkActiveOptions: "routerLinkActiveOptions"
      },
      ngContentSelectors: _c0,
      decls: 5,
      vars: 3,
      consts: [[3, "href", "target", "ngClass", 4, "ngIf"], [3, "ngClass", "routerLink", "routerLinkActive", "queryParams", "fragment", "routerLinkActiveOptions", 4, "ngIf"], [4, "ngIf"], ["content", ""], [3, "href", "target", "ngClass"], [4, "ngTemplateOutlet"], [3, "ngClass", "routerLink", "routerLinkActive", "queryParams", "fragment", "routerLinkActiveOptions"]],
      template: function LinkComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, LinkComponent_a_0_Template, 3, 5, "a", 0)(1, LinkComponent_a_1_Template, 3, 8, "a", 1)(2, LinkComponent_span_2_Template, 2, 1, "span", 2)(3, LinkComponent_ng_template_3_Template, 1, 0, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", !(ctx.button == null ? null : ctx.button.routerLink == null ? null : ctx.button.routerLink.length) && (ctx.button == null ? null : ctx.button.url) && !ctx.noLink);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", (ctx.button == null ? null : ctx.button.routerLink == null ? null : ctx.button.routerLink.length) && !ctx.noLink);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.noLink || !(ctx.button == null ? null : ctx.button.routerLink == null ? null : ctx.button.routerLink.length) && !ctx.button.url);
        }
      },
      dependencies: [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i2.RouterLink, i2.RouterLinkActive],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinkComponent, [{
    type: Component,
    args: [{
      selector: 'fstr-link',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<a *ngIf=\"!button?.routerLink?.length && button?.url && !noLink\" [href]=\"button.url\" [target]=\"target\" [ngClass]=\"cssClasses\">\r\n  <span *ngIf=\"showText\">{{ button.text }}</span>\r\n  <ng-container *ngTemplateOutlet=\"content\"></ng-container>\r\n</a>\r\n<a\r\n  [ngClass]=\"cssClasses\"\r\n  *ngIf=\"button?.routerLink?.length && !noLink\"\r\n  [routerLink]=\"button.routerLink\"\r\n  [routerLinkActive]=\"routerLinkActive\"\r\n  [queryParams]=\"queryParams\"\r\n  [fragment]=\"fragment\"\r\n  [routerLinkActiveOptions]=\"routerLinkActiveOptions\"\r\n>\r\n  <span *ngIf=\"showText\">{{ button.text }}</span>\r\n  <ng-container *ngTemplateOutlet=\"content\"></ng-container>\r\n</a>\r\n\r\n<span *ngIf=\"noLink || (!button?.routerLink?.length && !button.url)\">\r\n  <ng-container *ngTemplateOutlet=\"content\"></ng-container>\r\n</span>\r\n\r\n<ng-template #content>\r\n  <ng-content></ng-content>\r\n</ng-template>\r\n"
    }]
  }], null, {
    button: [{
      type: Input
    }],
    showText: [{
      type: Input
    }],
    noLink: [{
      type: Input
    }],
    cssClasses: [{
      type: Input
    }],
    target: [{
      type: Input
    }],
    routerLinkActive: [{
      type: Input
    }],
    queryParams: [{
      type: Input
    }],
    fragment: [{
      type: Input
    }],
    routerLinkActiveOptions: [{
      type: Input
    }]
  });
})();
class ButtonModule {
  static {
    this.ɵfac = function ButtonModule_Factory(t) {
      return new (t || ButtonModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ButtonModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, RouterModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonModule, [{
    type: NgModule,
    args: [{
      declarations: [LinkComponent],
      exports: [LinkComponent],
      imports: [CommonModule, RouterModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ButtonModule, LinkComponent };
