@if (selectedQuestionnaire$(); as questionnaire) {
  <article class="questions-intro">
    <app-header
      [title]="questionnaire.questionnaire.title"
      [intro]="questionnaire.questionnaire.description"
      [image]="questionnaire.questionnaire.introImage"
      [subtitle]="questionnaire.questionnaire.introTitle"
    >
      <button class="button button-link button--dark position-relative z-3 mt-4 mb-n5 d-flex" [routerLink]="['/vragenlijsten']" header>
        <fstr-icon anchor="icon-arrow-left"></fstr-icon>
        <span>{{ 'terug-naar-vragenlijsten-knoptekst' | fromDictionary }}</span>
      </button>

      <div body>
        <button class="button button--primary w-fit-content mb-4 mb-md-0" [routerLink]="['/vragenlijsten', questionnaire.id, 'vragen']">
          @if (selectedQuestionnaire$().progress === 100) {
            <span class="fs-5">{{ 'vragenlijst-overzicht-card-afgerond-button-label' | fromDictionary }}</span>
          } @else {
            <span class="fs-5">{{ 'introductie-vragenlijst-starten' | fromDictionary }}</span>
          }

          <fstr-icon anchor="icon-arrow-right"></fstr-icon>
        </button>
      </div>
    </app-header>

    @if (questionnaire.questionnaire.explanationTitle || questionnaire.questionnaire.explanationText) {
      <aside class="container z-2 position-relative">
        <div class="questions-intro__explanation">
          <!-- todo: weghalen z-indexes als util class en logica opvangen in background component -->
          <div class="card shadow-sm position-relative shadow-lg flex-row-reverse align-items-center card--pull-x card--triangle-top">
            <div class="card-body">
              <h2 class="fs-5 mb-3">{{ questionnaire.questionnaire.explanationTitle }}</h2>
              <div class="d-flex gap-3 gap-md-4 questions-intro__explanation__content">
                <p class="fst-italic fs-5">{{ questionnaire.questionnaire.explanationText | stripHtml }}</p>
              </div>
            </div>
            @if (questionnaire?.questionnaire?.explanationImage?.url) {
              <figure class="ms-4 mb-0 questions-intro__explanation__image">
                <fstr-image [image]="questionnaire.questionnaire.explanationImage"></fstr-image>
              </figure>
            }
          </div>
        </div>
      </aside>
    }

    <main class="section-outset-y">
      <div class="container pb-6">
        <h2 class="fs-3 mb-4">{{ questionnaire.questionnaire.pointsTitle }}</h2>
        <ul class="intro-points__list">
          @for (point of questionnaire.questionnaire.points; track point.id) {
            <li class="intro-points__list__item">
              <span class="d-flex align-items-md-center fs-5">
                <fstr-icon [anchor]="point.icon || 'list-icon-circle-check'"></fstr-icon>
                <span class="ms-2">{{ point.title }}</span>
              </span>
            </li>
          }
        </ul>
      </div>
    </main>
  </article>
}
