import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DynamicFormModule } from '@teamfoster/dynamic-forms';
import { HttpClientModule } from '@angular/common/http';
import { FormStepperComponent } from './components/form-stepper/form-stepper.component';
import { QuillModule } from 'ngx-quill';
import { IconModule } from '@teamfoster/sdk/icon';
import { ProgressBarComponent } from '../core/components/progress-bar/progress-bar.component';

@NgModule({
  declarations: [FormStepperComponent],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    DictionaryNgrxModule,
    DynamicFormModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TextUtilityModule,
    HttpClientModule,
    FormsModule,
    QuillModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatCheckboxModule,
    IconModule,
    ProgressBarComponent,
  ],
  exports: [FormStepperComponent],
})
export class FormModule {}
