import { createReducer, on } from '@ngrx/store';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import * as pageActions from '../actions/questionnaires.action';
import { Questionnaire } from '../../models';
import { UserQuestionnaire } from '../../models/user-questionnaire.model';

export interface QuestionnairesState {
  entities: { [id: number]: UserQuestionnaire };
  loaded: number[];
  loading: number[];
  overviewLoaded: boolean;
  overviewLoading: boolean;
  error?: any;
}

export const initialState: QuestionnairesState = {
  entities: {},
  loaded: [],
  loading: [],
  overviewLoading: false,
  overviewLoaded: false,
};

export const QuestionsListReducer = createReducer(
  initialState,
  on(pageActions.LoadQuestionnaires, (state: QuestionnairesState) => {
    return {
      ...state,
      overviewLoading: true,
    };
  }),
  on(pageActions.LoadQuestionnairesSuccess, (state: QuestionnairesState, { questionnaires }) => {
    const entities = ToEntities(questionnaires, 'id', state.entities);
    return {
      ...state,
      entities,
      overviewLoading: false,
      overviewLoaded: true,
      loaded: [],
    };
  }),
  on(pageActions.LoadQuestionnairesFail, (state: QuestionnairesState, { error }) => {
    return {
      ...state,
      overviewLoading: false,
      overviewLoaded: false,
      error,
    };
  }),
  on(pageActions.LoadQuestionnaireById, (state: QuestionnairesState, { id }) => {
    return {
      ...state,
      loading: [...state.loading, id],
    };
  }),
  on(pageActions.LoadQuestionnaireByIdSuccess, (state: QuestionnairesState, { questionnaire }) => {
    const entities = { ...state.entities, [questionnaire.id]: questionnaire };
    return {
      ...state,
      entities,
      loading: state.loading.filter(id => id !== questionnaire.id),
      loaded: [...state.loaded, questionnaire.id],
    };
  }),
  on(pageActions.LoadQuestionnaireByIdFail, (state: QuestionnairesState, { error, id }) => {
    return {
      ...state,
      loading: state.loading.filter(id => id !== id),
      loaded: state.loaded.filter(id => id !== id),
      error,
    };
  }),
  on(pageActions.UpdateQuestionnaireProgress, (state: QuestionnairesState, { questionnaireData }) => {
    return {
      ...state,
      loading: [...state.loading, questionnaireData.id],
    };
  }),
  on(pageActions.UpdateQuestionnaireProgressSuccess, (state: QuestionnairesState, { questionnaire }) => {
    const entities = { ...state.entities, [questionnaire.id]: questionnaire };
    return {
      ...state,
      entities,
      loading: state.loading.filter(id => id !== questionnaire.id),
      loaded: [...state.loaded, questionnaire.id],
    };
  }),
  on(pageActions.ClearQuestionnaires, (state: QuestionnairesState) => {
    return {
      ...initialState,
    };
  })
);

export const getQuestionnairesEntities = (state: QuestionnairesState) => state.entities;

export const getQuestionnairesLoading = (state: QuestionnairesState) => state.loading;
export const getQuestionnairesLoaded = (state: QuestionnairesState) => state.loaded;

export const getQuestionnairesOverviewLoading = (state: QuestionnairesState) => state.overviewLoading;
export const getQuestionnairesOverviewLoaded = (state: QuestionnairesState) => state.overviewLoaded;
