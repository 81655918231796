import * as from from './questionnaires.reducer';
import * as fromAnswers from './answer.reducer';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export const featureKey = 'questionsList';

export interface QuestionnaireState {
  questionnaires: from.QuestionnairesState;
  answers: fromAnswers.AnswersState;
}

export const reducers: ActionReducerMap<QuestionnaireState> = {
  questionnaires: from.QuestionsListReducer,
  answers: fromAnswers.AnswersReducer,
};

export const getQuestionnaireState = createFeatureSelector<QuestionnaireState>(featureKey);
