import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError } from 'rxjs/operators';

import * as fromStore from '../store';
import { of } from 'rxjs';

export const AnswerGuard: CanActivateFn = route => {
  const store = inject(Store);
  return store
    .select(fromStore.getAnswersLoaded)
    .pipe(
      tap(loaded => {
        if (!loaded) {
          //id ??
          const id = +route.params['questionsListId'] || +route.data['questionsListId'];
          store.dispatch(fromStore.LoadAnswersById({ id }));
        }
      }),
      filter(loaded => loaded),
      take(1)
    )
    .pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
};
