import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild, computed, inject, input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  CreateAnswer,
  UpdateAnswer,
  UpdateQuestionnaireProgress,
  getAnswersSavingFailed,
  getOrderedAnswers,
  getSelectedQuestionnaire,
  getSelectedQuestionnaireProgress,
  getSelectedQuestionnaireProgressGroup,
  getSelectedQuestionnaireWithAnswers,
} from '../../store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { Validators } from '@angular/forms';
import { DynamicFormField, FormComponent } from '@teamfoster/dynamic-forms';
import { Router } from '@angular/router';
import { UrlRewritePipe } from '@teamfoster/sdk/text-utility';
import { FormStepperComponent } from 'src/app/form/components/form-stepper/form-stepper.component';
import { Subject, debounceTime, take, takeUntil } from 'rxjs';
import { AnswerData, UserQuestionnaireData } from '../../models';
import { QuestionType } from '../../models/question-type.enum';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-questionnaire-detail',
  templateUrl: './questionnaire-detail.component.html',
  styleUrl: './questionnaire-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnaireDetailComponent implements AfterViewInit, OnDestroy {
  private store = inject(Store);
  private router = inject(Router);

  selectedQuestionnaire$ = toSignal(this.store.select(getSelectedQuestionnaireWithAnswers).pipe(take(1)));
  userQuestionnaire$ = this.store.selectSignal(getSelectedQuestionnaire);
  userQuestionnaireProgress$ = this.store.selectSignal(getSelectedQuestionnaireProgress);
  answers$ = this.store.selectSignal(getOrderedAnswers);
  anwserSaveError$ = this.store.selectSignal(getAnswersSavingFailed);
  latestAnsweredGroupNumber$ = toSignal(this.store.select(getSelectedQuestionnaireProgressGroup).pipe(take(1)));

  prefix = 'vragenlijst';
  private lang = inject(LanguageService);
  private dict = new FromDictionaryPipe(this.lang);
  private urlRewrite = new UrlRewritePipe();
  private unsubscribe$ = new Subject<void>();

  // TODO repalce with real submit/error handling
  submitting = false;
  hasError = false;

  @ViewChild('form') form?: FormStepperComponent;

  currentValue: { [key: string]: any } = {};

  fieldsets$ = computed(() => {
    const questionListGroups = this.selectedQuestionnaire$()?.questionsGroups;
    return questionListGroups?.map(group => {
      return {
        name: this.titleToFieldsetLabel(group.title),
        title: group.title,
        description: this.dict.transform(`${this.titleToFieldsetLabel(group.title)}-beschrijving`, false),
      };
    });
  });

  titleToFieldsetLabel(title: string) {
    return title.replace(/\s/g, '-').toLowerCase();
  }

  questionFormFields$ = computed(() => {
    const questionListGroups = this.selectedQuestionnaire$()?.questionsGroups;
    const fieldsets = this.fieldsets$();

    return questionListGroups?.flatMap(group => {
      return group.questions.map((question, i) => {
        let anwser = question.answer?.answerText || undefined;

        if (question.answer?.multipleChoiceAnswer) {
          anwser = JSON.parse(question.answer?.multipleChoiceAnswer);
        }

        return {
          inputType: 'text',
          label: this.dict.transform(`${this.prefix}-antwoord-label`),
          title: question.title,
          intro: question.description,
          name: `question_${question.id}`,
          validators: question.required ? [Validators.required] : [],
          value: anwser,
          placeholder: this.dict.transform(`${this.prefix}-placeholder`),
          fieldType: this.getFormControl(question.questionType),
          selectOptions: question.multipleChoiceOptions.map(option => {
            return {
              title: option.answerText,
              id: option.id,
            };
          }),
          questionGroupId: group.id,
          multipleAnwsersPossible: question.isMultipleChoice,
          order: 0,
          fieldset: this.titleToFieldsetLabel(group.title),
        } as DynamicFormField;
      });
    });
  });

  getFormControl(type: QuestionType) {
    switch (type) {
      case QuestionType.OPEN:
        return 'question-open';
      case QuestionType.RANGE:
        return 'question-slider';
      case QuestionType.MULTIPLE_CHOICE:
        return 'question-multiple-choice';
      default:
        return 'question-open';
    }
  }

  handleSubmit(data: any) {
    this.submitting = true;

    if (this.hasError) {
      this.submitting = false;
      return;
    }

    setTimeout(() => {
      this.router.navigate([
        '/',
        'vragenlijsten',
        this.userQuestionnaire$()?.id,
        this.urlRewrite.transform(this.selectedQuestionnaire$()?.title || ''),
        'einde',
      ]);
    }, 2000);
  }

  answeredQuestions() {
    const form = this.form?.form;
    let answered = 0;
    if (form) {
      Object.keys(form.controls).forEach(key => {
        const control = form.get(key);
        if (control?.value && control?.errors === null && control?.validator?.(control) === null) {
          answered++;
        }
      });
    }

    return answered;
  }

  saveValue(groupId: number, questionId: number, value: any) {
    const answerData: AnswerData = {
      id: 0,
      UserQuestionnaireId: this.userQuestionnaire$().id,
      questionId: questionId,
      answerText: '',
      multipleChoiceAnswer: '',
    };

    if (typeof value === 'object') {
      answerData.multipleChoiceAnswer = JSON.stringify(value);
    } else {
      answerData.answerText = value;
    }

    answerData.id =
      this.answers$().find(a => a.questionId === questionId && a.questionsListUserId === this.userQuestionnaire$().id)?.id || 0;

    if (answerData.id > 0) {
      this.store.dispatch(UpdateAnswer({ answerData: answerData }));
    } else {
      this.store.dispatch(CreateAnswer({ answerData: answerData }));
    }

    const answerstotal = this.answeredQuestions();
    const questionsTotal = this.questionFormFields$()?.filter(q => q.validators?.includes(Validators.required)).length || 0;
    const avg = Math.round((answerstotal / questionsTotal) * 100);

    const userQuestionnaireData: UserQuestionnaireData = {
      id: this.userQuestionnaire$().id,
      progress: avg,
    };

    this.store.dispatch(UpdateQuestionnaireProgress({ questionnaireData: userQuestionnaireData }));
  }

  ngAfterViewInit(): void {
    this.currentValue = { ...this.form?.form.value };
    this.form?.form.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(250)).subscribe(e => {
      const changedFields = Object.keys(e).filter(key => e[key] !== this.currentValue[key]);

      const updatedFieldName = changedFields[0];

      if (!updatedFieldName) return;
      const questionId = +updatedFieldName.split('_')[1];

      const question = this.questionFormFields$()?.find(q => q.name === updatedFieldName);
      const groupId = question?.['questionGroupId'];

      this.saveValue(groupId, questionId, e[updatedFieldName]);

      // After save
      this.currentValue = { ...this.form?.form.value };
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
