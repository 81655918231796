import { createAction, props } from '@ngrx/store';
import { Answer, AnswerData } from '../../models';
export const LoadAnswersById = createAction('[Answers] Load Answers by User Id', props<{ id: number }>());
export const LoadAnswersByIdSuccess = createAction('[Answers]  Load Answers by User Id Success', props<{ answers: Answer[] }>());
export const LoadAnswersByIdFail = createAction('[Answers] Load Answers by User Id Fail', props<{ error: any; id: number }>());

export const CreateAnswer = createAction('[Create Answer] Create Answer', props<{ answerData: AnswerData }>());
export const CreateAnswerSuccess = createAction('[Answer] Create Answer Success', props<{ answer: Answer }>());
export const CreateAnswerFail = createAction('[Answer] Create Answer Fail', props<{ error: any }>());

export const UpdateAnswer = createAction('[Answers] Update Answer', props<{ answerData: AnswerData }>());
export const UpdateAnswerSuccess = createAction('[Answers] Update Answer Success', props<{ answer: Answer; answerData: AnswerData }>());
export const UpdateAnswerFail = createAction('[Answers] Update Answer Fail', props<{ error: any }>());

export const DeleteAnswer = createAction('[Answers] Delete Answer', props<{ id: number }>());
export const DeleteAnswerSuccess = createAction('[Answers] Delete Answer Success', props<{ answer: Answer }>());
export const DeleteAnswerFail = createAction('[Answers] Delete Answer Fail', props<{ error: any }>());

export const ClearAnswers = createAction('[Answers] Clear');
