<div class="progress">
  <div
    class="progress-bar"
    role="progressbar"
    [style.--progress]="progress"
    [attr.aria-valuenow]="progress"
    aria-valuemin="0"
    aria-valuemax="100"
  ></div>
  @if (showLabel) {
    <span class="progress__label">{{ progress || 0 | number: '1.0-0' }}%</span>
  }
</div>
