<article class="">
  <app-header [title]="'vragen-lijst-overzicht-titel' | fromDictionary">
    <div footer>
      @if (questionnairesInProgress$().length) {
        <h4 class="h4 mb-4 mt-5">{{ 'vragen-lijst-overzicht-progressie-titel' | fromDictionary }}</h4>
        <ul class="list-unstyled">
          @for (q of questionnairesInProgress$(); track q.id) {
            <li class="mt-3">
              <app-questionnaire-card [cssClasses]="'card--pull-x'" [userQuestionnaire]="q">
                <div class="card-footer pt-0" footer>
                  <app-progress-bar [progress]="q.progress ? q.progress : 0" [showLabel]="false"></app-progress-bar>
                </div>
              </app-questionnaire-card>
            </li>
          }
        </ul>
      }

      <!-- Empty state -->
      @if (!userQuestionnaires$().length) {
        <p>{{ 'vragen-lijst-overzicht-leeg' | fromDictionary }}</p>
      }
    </div>
  </app-header>

  @if (questionnairesTodo$().length) {
    <main class="section-inset-y container">
      <h3 class="h4 mb-4 text-primary">{{ 'vragen-lijst-overzicht-nieuwe-vragen-titel' | fromDictionary }}</h3>
      <ul class="list-unstyled">
        @for (q of questionnairesTodo$(); track q.id) {
          <li class="mb-3">
            <app-questionnaire-card [cssClasses]="'card--pull-x'" [userQuestionnaire]="q"></app-questionnaire-card>
          </li>
        }
      </ul>
    </main>
  }

  @if (questionnairesDone$().length) {
    <main class="section-inset-y container">
      <h3 class="h4 mb-4 text-primary">{{ 'vragen-lijst-overzicht-gedane-vragen-titel' | fromDictionary }}</h3>
      <ul class="list-unstyled">
        @for (q of questionnairesDone$(); track q.id) {
          <li class="mb-3">
            <app-questionnaire-card [cssClasses]="'card--pull-x'" [userQuestionnaire]="q"></app-questionnaire-card>
          </li>
        }
      </ul>
    </main>
  }
</article>
